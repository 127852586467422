import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import parse from 'html-react-parser';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import ModalContent from "../modal";
import "./style.css";
import { motion } from "framer-motion";

const Offer = () => {

    const data = useStaticQuery(graphql`
        query Offer {
            wpCtaOffer {
                ctaOfferMeta {
                  content
                  image {
                    sourceUrl
                  }
                }
            }
            wpThemeColor {
                themeColorMeta {
                  primary
                  secondary
                }
            }
        }
    `)

    const primarycolor = data.wpThemeColor.themeColorMeta.primary
    const secondarycolor = data.wpThemeColor.themeColorMeta.secondary

    const content = data.wpCtaOffer.ctaOfferMeta.content
    const img = data.wpCtaOffer.ctaOfferMeta.image.sourceUrl

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Box sx={{ flexGrow: 1, backgroundImage: `url("${img}")`, backgroundAttachment: 'fixed', backgroundSize: 'cover', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat no-repeat' }} id="offer">
                <Grid container spacing={0} sx={{ px: { xs: 5, md: 10 }, py: { xs: 5, md: 5 }, backgroundColor: 'rgba(0, 0, 0, 0.7)', alignItems: 'center' }}>
                    <Grid item xs={12} md={8}>
                        <Typography variant="h4" sx={{ color: '#fff', width: { sm: '100%', lg: '70%' }, mb: {xs: '30px', md: 'unset'} }}>
                            {parse(content)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{textAlign:'center'}}>
                        <Box>
                        <motion.div animate={{ rotate: [0, 6, 0, -6, 0] }} transition={{ duration: 0.5, repeat: Infinity, repeatDelay: 2 }}>
                                <Button variant="contained" sx={{ width: '205px', padding: '6px 30px', backgroundColor: secondarycolor, "&:hover": { backgroundColor: secondarycolor } }} onClick={handleOpen}>
                                    Enquire Now
                                </Button>
                            </motion.div>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalContent closeBtn={handleClose} />
            </Modal>
        </>
    );
};

export default Offer;