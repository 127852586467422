import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Parse from "html-react-parser";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";

const settings = {
    infinite: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 3000,
};

const fnReadMore = (action) => {
    if (action == 'more') {
        document.getElementById('readMoreDevBtn').style.display = 'none'
        document.getElementById('readMoreDevDiv').style.display = 'block'
        document.getElementById('CloseDevBtn').style.display = 'block'
    } else {
        document.getElementById('readMoreDevBtn').style.display = 'block'
        document.getElementById('readMoreDevDiv').style.display = 'none'
        document.getElementById('CloseDevBtn').style.display = 'none'
    }
}

const Developer = () => {

    const data = useStaticQuery(graphql`
    query Allabout {
        allWpDeveloperimage(sort: {fields: developerImagesMeta___order}) {
            edges {
              node {
                developerImagesMeta {
                  image {
                    sourceUrl
                  }
                  title
                }
              }
            }
          }
        wpAboutdeveloper {
            aboutDeveloperMeta {
                developertext
                developertextPara
            }
        }
        wpThemeColor {
            themeColorMeta {
              primary
              secondary
            }
        }
      }
`)

const primarycolor = data.wpThemeColor.themeColorMeta.primary
const secondarycolor = data.wpThemeColor.themeColorMeta.secondary

    return (
        <>
            <Box className="section" sx={{ flexGrow: 1 }} id="developer">
                <Grid container spacing={4} sx={{ px: { xs: 2, md: 10, lg: 15 }, py: { xs: 4, lg: 5 } }}>
                    <Grid item xs={12} lg={6}>
                        <Typography variant="h4" sx={{ textAlign: 'center', textTransform: 'uppercase', mb: 1 }}>
                            OTHER PROJECTS
                        </Typography>
                        <hr />
                        <SlickSlider {...settings}>
                            {data.allWpDeveloperimage.edges.map((item, index) => (
                                <div key={index}>
                                    <Box sx={{ display: 'table', width: '90%', margin: 'auto', mt: 0, mb: 3, boxShadow: '0px 10px 20px 2px rgba(0, 0, 0, 0.25)', borderRadius: '14px', backgroundImage: `url(${item.node.developerImagesMeta.image.sourceUrl})`, height: { xs: '20em', sm: '25em' }, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }} >
                                        <Box sx={{ display: 'table-cell', verticalAlign: 'bottom' }}>
                                            <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.4)', color: '#fff', padding: '6px 10px', margin: 'auto', textAlign: 'center', borderBottomRightRadius: '14px', borderBottomLeftRadius: '14px' }}>
                                                <Typography variant="h6" sx={{ textAlign: 'center', color: '#fff', mb: 0 }}>
                                                    {item.node.developerImagesMeta.title}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </div>
                            ))}
                        </SlickSlider>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Typography variant="h4" sx={{ textAlign: 'center', textTransform: 'uppercase', mb: 1 }}>
                            ABOUT DEVELOPER
                        </Typography>
                        <hr />
                        <Typography variant="subtitle1" sx={{ textAlign: 'justify', mb: 0 }}>
                            {Parse(data.wpAboutdeveloper.aboutDeveloperMeta.developertextPara)}
                        </Typography>
                        <Button id="readMoreDevBtn" variant="contained" size="small" sx={{ float: 'right', backgroundColor: secondarycolor, "&:hover": { backgroundColor: secondarycolor } }} onClick={() => fnReadMore("more")}>
                            Read More
                        </Button>
                        <Typography variant="subtitle1" id="readMoreDevDiv" style={{ display: 'none', textAlign: 'justify', mb: 0 }}>
                            {Parse(data.wpAboutdeveloper.aboutDeveloperMeta.developertext)}
                        </Typography>
                        <Button id="CloseDevBtn" style={{ display: 'none' }} variant="contained" size="small" sx={{ float: 'right', backgroundColor: secondarycolor, "&:hover": { backgroundColor: secondarycolor } }} onClick={() => fnReadMore("close")}>
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </Box>

        </>
    );
};

export default Developer;