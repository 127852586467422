import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ModalContent from "../modal";
import WhatsApp from "../../images/Whatsapp.png";
import "./style.css";

const fnRedirect = (link) => {
    const url = document.location.href;
    let source = "BLANK";
    if(url.indexOf("?") != -1){
        const para = url.split("?")[1];
        let sources;
        para.split("&").forEach(function(item) {
        if(item.indexOf("utm_source") != -1){
                sources = item.split("=")[1];
            }   
        });
        if (typeof window !== 'undefined') {
            window.location.href = link+"?utm_source="+sources;
        }
    }else{
        if (typeof window !== 'undefined') {
            window.location.href = link;
        }
    }
}

const BottomBar = () => {

    const data = useStaticQuery(graphql`
    query BottomBar {
            wpThemeColor {
                themeColorMeta {
                primary
                secondary
                }
            }
            wpContact {
                contactMeta {
                  primaryNumber
                  secondaryNumber
                }
            }
        }
    `)

    const primarycolor = data.wpThemeColor.themeColorMeta.primary
    const secondarycolor = data.wpThemeColor.themeColorMeta.secondary
    const date = new Date();
    const time = date.getHours();
    let contact_number;
    if(time < 19){
        contact_number = data.wpContact.contactMeta.primaryNumber
    }else{
        contact_number = data.wpContact.contactMeta.secondaryNumber
    }

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' }, flexGrow: 1, bgcolor: primarycolor, position: 'fixed', bottom: '0px', width: '100%', zIndex: 999 }}>
                <Grid container spacing={0}>
                    <Grid item xs={5}>
                        <Typography onClick={handleOpen} variant="subtitle2" sx={{ textAlign: 'center', textTransform: 'uppercase', cursor: 'pointer', color: '#fff', m: 0, padding: '12px 0px' }}>
                            Get Quote
                        </Typography>
                    </Grid>
                    <Grid item xs={2} textAlign="center" sx={{ borderInline: '1px solid #fff' }} onClick={() => fnRedirect("/whatsapp")}>
                        <img src={WhatsApp} alt="Whatsapp" style={{ width: '40px' }} />
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="subtitle2" sx={{ textAlign: 'center', textTransform: 'uppercase', color: '#fff', m: 0, padding: '12px 0px' }}>
                            <a href={`tel:+${contact_number}`} style={{ color: '#fff', textDecoration: 'none' }}>+{contact_number}</a>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalContent closeBtn={handleClose} />
            </Modal>
        </>
    );
};

export default BottomBar;