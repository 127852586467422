import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Modal from '@mui/material/Modal';
import ModalContent from "../modal";
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";

var title = ""
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 6, px: { sm: 3, md: 5, lg: 10 } }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


const Amenities = () => {

  const data = useStaticQuery(graphql`
    query Amenities {
      allWpAmenitiesTab(sort: {fields: amenitiesTabsMeta___order}) {
        edges {
          node {
            amenitiesTabsMeta {
              title
            }
          }
        }
      }
      allWpAmenitiesIcon(sort: {fields: amenitiesIconsMeta___order}) {
        edges {
          node {
            amenitiesIconsMeta {
              title
              tabsTitle
              icon {
                sourceUrl
              }
            }
          }
        }
      }
      wpThemeColor {
        themeColorMeta {
          primary
          secondary
        }
      }
    }
`)

const primarycolor = data.wpThemeColor.themeColorMeta.primary
const secondarycolor = data.wpThemeColor.themeColorMeta.secondary

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  let count = 0;
  function fnSlider(panel_title, tab_title, img) {
    if (panel_title.trim() == tab_title.trim()) {
      count++;
      return (<div key={count}>
        <Box sx={{ mx: { xs: 3, lg: 5 }, mb: 4, boxShadow: '0px 10px 20px 2px rgba(0, 0, 0, 0.25)', borderRadius: '14px', backgroundImage: `url(${img})`, height: { xs: '20em', sm: '30em' }, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}} />
      </div>)
    }
  }

  return (
    <>
        <Box className="section" sx={{ flexGrow: 1 }} id="amenities">
          <Grid container spacing={0} sx={{ px: { xs: 2, md: 10 }, py: { xs: 4, md: 5 } }}>
            <Grid item xs={12}>
                <Typography variant="h4" sx={{ textAlign: 'center', textTransform: 'uppercase', mb: 1 }}>
                  Amenities
                </Typography>
                <hr />
              <Box sx={{ width: '100%' }}>
                  <Tabs
                    sx={{
                      width: {xs: '100%', sm:'fit-content'},
                      margin: 'auto',
                      color: '#fff',
                      borderRadius: '40px',
                    }}
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    indicatorColor="secondary"
                    textColor="inherit"
                    aria-label="full width tabs example"
                    centered
                  >
                    {data.allWpAmenitiesTab.edges.map((item, index) => (
                      <Tab label={item.node.amenitiesTabsMeta.title} {...a11yProps({ index })} />
                    ))}
                  </Tabs>
                  {data.allWpAmenitiesTab.edges.map((tabItem, tabIndex) => (
                    <TabPanel value={value} index={tabIndex} dir={theme.direction}>
                        <SlickSlider
                          customPaging={function (index) {
                            var paging = data.allWpAmenitiesIcon.edges.filter(edge => edge.node.amenitiesIconsMeta.tabsTitle.toLowerCase() == tabItem.node.amenitiesTabsMeta.title.toLowerCase())
                            title = paging[index].node.amenitiesIconsMeta.title;
                            return (
                              <a style={{ backgroundColor: "rgba(0, 0, 0, 0.50)", color: "#fff", padding: "6px 10px", borderRadius: "50px", fontSize: '14px', textTransform: 'capitalize' }}>
                                {title}
                              </a>
                            );
                          }}
                          infinite={true}
                          dots={true}
                          arrows={false}
                          slidesToShow={1}
                          slidesToScroll={1}
                          lazyLoad={true}
                          autoplay={true}
                          autoplaySpeed={3000}
                        >
                          {data.allWpAmenitiesIcon.edges.map((item, index) => (
                            fnSlider(item.node.amenitiesIconsMeta.tabsTitle.toLowerCase(), tabItem.node.amenitiesTabsMeta.title.toLowerCase(), item.node.amenitiesIconsMeta.icon.sourceUrl)
                          ))}
                        </SlickSlider>
                    </TabPanel>
                  ))}
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Button sx={{ width: '205px', border: `1px solid ${secondarycolor}`, color: secondarycolor, "&:hover": { border: `1px solid ${secondarycolor}`, color: secondarycolor } }} onClick={handleOpen} variant="outlined" startIcon={<FileDownloadIcon className="bounce" />}>
                Get Brochure
              </Button>
            </Grid>
          </Grid>
        </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent modalTitle="Download Amenities" closeBtn={handleClose} />
      </Modal>
    </>
  );
};

export default Amenities;