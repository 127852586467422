import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import Modal from '@mui/material/Modal';
import ModalContent from "../modal";
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";

var title = ""
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 6, px: { sm: 3, md: 5, lg: 10 } }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
const settings = {
    infinite: false,
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const Plans = () => {

    const data = useStaticQuery(graphql`
        query Project {
            allWpProjectTab(sort: {fields: projectTabMeta___order}) {
                edges {
                    node {
                        projectTabMeta {
                            title
                        }
                    }
                }
            }
            allWpProjectImage(sort: {fields: projectImageMeta___order}) {
                edges {
                  node {
                    projectImageMeta {
                      title
                      tabTitle
                      image {
                        sourceUrl
                      }
                    }
                  }
                }
            }
            wpThemeColor {
                themeColorMeta {
                  primary
                  secondary
                }
            }
        }
    `)

    const primarycolor = data.wpThemeColor.themeColorMeta.primary
    const secondarycolor = data.wpThemeColor.themeColorMeta.secondary

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    let count = 0;
    function fnSlider(panel_title, tab_title, title, img) {
        if (panel_title.trim() == tab_title.trim()) {
            count++;
            return (<Box key={count}>
                <Box href={img} data-fancybox="plans" data-caption={title} sx={{ textAlign: 'right', cursor: 'pointer', boxShadow: '0px 10px 20px 2px rgba(0, 0, 0, 0.25)', borderRadius: '14px', backgroundImage: `url(${img})`, height: { xs: '16em', sm: '20em' }, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }} >
                    <ZoomInIcon sx={{ color: '#fff', backgroundColor: 'rgba(0, 0, 0, 0.4)', padding: '5px', fontSize: '2em', borderTopRightRadius: '14px', borderBottomLeftRadius: '14px' }} />
                </Box>
                <Typography variant="h6" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.4)', color: '#fff', padding: '6px 10px', borderBottomRightRadius: '14px', borderBottomLeftRadius: '14px', margin: 'auto', textAlign: 'center', position: 'relative', bottom: '44px' }}>
                    {title}
                </Typography>
            </Box>)
        }
    }

    return (
        <>
            <Box className="section" sx={{ flexGrow: 1 }} id="plan">
                <Grid container spacing={0} sx={{ px: { xs: 0, md: 10 }, py: { xs: 4, md: 5 } }}>
                    <Grid item xs={12}>
                        <Typography variant="h4" sx={{ textAlign: 'center', textTransform: 'uppercase', mb: 3 }}>
                            Project Plans
                        </Typography>
                        <hr />
                        <Box sx={{ width: '100%' }}>
                            <Tabs
                                sx={{
                                    width: { xs: '100%', sm: 'fit-content' },
                                    margin: 'auto',
                                    color: '#fff',
                                    borderRadius: '40px',
                                }}
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                                indicatorColor="secondary"
                                textColor="inherit"
                                aria-label="full width tabs example"
                                centered
                            >
                                {data.allWpProjectTab.edges.map((item, index) => (
                                    <Tab label={item.node.projectTabMeta.title} {...a11yProps({ index })} />
                                ))}
                            </Tabs>
                            {data.allWpProjectTab.edges.map((tabItem, tabIndex) => (
                                <TabPanel value={value} index={tabIndex} dir={theme.direction}>
                                    <SlickSlider {...settings}>
                                        {data.allWpProjectImage.edges.map((item, index) => (
                                            fnSlider(item.node.projectImageMeta.tabTitle.toLowerCase(), tabItem.node.projectTabMeta.title.toLowerCase(), item.node.projectImageMeta.title, item.node.projectImageMeta.image.sourceUrl)
                                        ))}
                                    </SlickSlider>
                                </TabPanel>
                            ))}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <Button sx={{ width: '205px', border: `1px solid ${secondarycolor}`, color: secondarycolor, "&:hover": { border: `1px solid ${secondarycolor}`, color: secondarycolor } }} onClick={handleOpen} variant="outlined" startIcon={<FileDownloadIcon className="bounce" />}>
                            Get Project Plan
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalContent modalTitle="Download Project Plan" closeBtn={handleClose} />
            </Modal>
        </>
    );
};

export default Plans;